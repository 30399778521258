<template>
  <v-card
    :class="{
      'card--flex-toolbar-xs': $vuetify.breakpoint.xsOnly,
      'card--flex-toolbar': $vuetify.breakpoint.smAndUp
    }"
  >
    <v-toolbar flat dense class="elevation-3" color="secondary">
      <v-toolbar-title class="body-2 white--text">{{
        this.$route.meta.subtitle
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            small
            fab
            dark
            class="mx-1"
            color="error"
            v-on="on"
            to="/membershiplist"
          >
            <v-icon dark>card_membership</v-icon>
          </v-btn>
        </template>
        <span>Vrste članarina</span>
      </v-tooltip>
      <v-btn small fab dark class="mx-1" color="primary" @click="reload">
        <v-icon dark>autorenew</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }">
      <v-container
        fluid
        fill-height
        class="pt-0"
        :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }"
      >
        <v-row no-gutters align-space-around justify-center>
          <v-col cols="12">
            <v-tabs v-model="tabs" show-arrows>
              <v-tab>IND.ČL. PRIVATNO</v-tab>
              <v-tab>IND.ČL. PODUZEĆE</v-tab>
              <v-tab>KOLEKTIVNE ČL.</v-tab>
              <v-tab>MEĐUNARODNE IND.ČL.</v-tab>
              <v-tab>MEĐUNARODNE KOL.ČL.</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs">
              <v-tab-item
                transition="scroll-y-transition"
                reverse-transition="scroll-y-transition"
              >
                <InteractiveTable
                  id="membershipStatusI"
                  ref="membershipStatusI"
                  class="xl-table"
                  :headers="[
                    { text: 'ID', value: 'id' },
                    { text: 'Članski broj', value: 'regnum' },
                    { text: 'Ime i prezime', value: 'name', width: 230 },
                    { text: 'Poduzeće', value: 'company', width: 250 },
                    { text: 'Status', value: 'status', align: 'end' },
                    { text: 'Iznos', value: 'amount', align: 'end' },
                    { text: 'Uplatnica', value: 'invoice_num' },
                    { text: 'Datum uplate', value: 'payment_date' }
                  ]"
                  apiLoad="membership/membershipstatuses.php?type=i"
                  :actions="[]"
                  defaultFilter="name"
                  yearFilter
                  :statusColorFunc="this.statusColorFunc"
                  defaultSort="payment_date"
                  defaultSortDesc
                ></InteractiveTable>
              </v-tab-item>
              <v-tab-item
                transition="scroll-y-transition"
                reverse-transition="scroll-y-transition"
              >
                <InteractiveTable
                  id="membershipStatusIp"
                  ref="membershipStatusIp"
                  class="xl-table"
                  :headers="[
                    { text: 'ID', value: 'id' },
                    { text: 'Članski broj', value: 'regnum' },
                    { text: 'Ime i prezime', value: 'name', width: 230 },
                    { text: 'Poduzeće', value: 'company', width: 250 },
                    { text: 'Status', value: 'status', align: 'end' },
                    { text: 'Iznos', value: 'amount', align: 'end' },
                    { text: 'Ponuda', value: 'quote_num' },
                    { text: 'Račun', value: 'invoice_num' },
                    { text: 'Datum uplate', value: 'payment_date' }
                  ]"
                  apiLoad="membership/membershipstatuses.php?type=ip"
                  :actions="[]"
                  defaultFilter="name"
                  yearFilter
                  :statusColorFunc="this.statusColorFunc"
                  defaultSort="payment_date"
                  defaultSortDesc
                ></InteractiveTable>
              </v-tab-item>
              <v-tab-item
                transition="scroll-y-transition"
                reverse-transition="scroll-y-transition"
              >
                <InteractiveTable
                  id="membershipStatusK"
                  ref="membershipStatusK"
                  class="xl-table"
                  :headers="[
                    { text: 'ID', value: 'id' },
                    { text: 'Članski broj', value: 'regnum' },
                    { text: 'Naziv', value: 'name' },
                    { text: 'Status', value: 'status', align: 'end' },
                    { text: 'Iznos', value: 'amount', align: 'end' },
                    { text: 'Ponuda', value: 'quote_num' },
                    { text: 'Račun', value: 'invoice_num' },
                    { text: 'Datum uplate', value: 'payment_date' }
                  ]"
                  apiLoad="membership/membershipstatuses.php?type=k"
                  :actions="[]"
                  defaultFilter="name"
                  yearFilter
                  :statusColorFunc="this.statusColorFunc"
                  defaultSort="payment_date"
                  defaultSortDesc
                ></InteractiveTable>
              </v-tab-item>
              <v-tab-item
                transition="scroll-y-transition"
                reverse-transition="scroll-y-transition"
              >
                <InteractiveTable
                  id="membershipStatusMi"
                  ref="membershipStatusMi"
                  class="xl-table"
                  :headers="[
                    { text: 'ID', value: 'id' },
                    { text: 'Ime i prezime', value: 'name', width: 200 },
                    { text: 'Poduzeće', value: 'company', width: 230 },
                    { text: 'Status', value: 'status', align: 'end' },
                    { text: 'Iznos', value: 'amount', align: 'end' },
                    { text: 'Ponuda', value: 'quote_num' },
                    { text: 'Račun', value: 'invoice_num' },
                    { text: 'Datum uplate', value: 'payment_date' }
                  ]"
                  apiLoad="membership/membershipstatuses.php?type=mi"
                  :actions="[]"
                  defaultFilter="name"
                  yearFilter
                  :statusColorFunc="this.statusColorFunc"
                  defaultSort="payment_date"
                  defaultSortDesc
                ></InteractiveTable>
              </v-tab-item>
              <v-tab-item
                transition="scroll-y-transition"
                reverse-transition="scroll-y-transition"
              >
                <InteractiveTable
                  id="membershipStatusMk"
                  ref="membershipStatusMk"
                  class="xl-table"
                  :headers="[
                    { text: 'ID', value: 'id' },
                    { text: 'Naziv', value: 'name' },
                    { text: 'Status', value: 'status', align: 'end' },
                    { text: 'Iznos', value: 'amount', align: 'end' },
                    { text: 'Ponuda', value: 'quote_num' },
                    { text: 'Račun', value: 'invoice_num' },
                    { text: 'Datum uplate', value: 'payment_date' }
                  ]"
                  apiLoad="membership/membershipstatuses.php?type=mk"
                  :actions="[]"
                  defaultFilter="name"
                  yearFilter
                  :statusColorFunc="this.statusColorFunc"
                  defaultSort="payment_date"
                  defaultSortDesc
                ></InteractiveTable>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import InteractiveTable from "@/components/InteractiveTable.vue";

export default {
  name: "MembershipStatus",
  data() {
    return {
      tabs: null
    };
  },
  components: {
    InteractiveTable
  },
  computed: {
    needsRefresh: function() {
      return this.$store.getters.membershipStatusNeedsRefresh;
    }
  },
  watch: {
    needsRefresh(needsRefresh) {
      if (needsRefresh) {
        this.reload();
      }
    }
  },
  methods: {
    reload() {
      if (this.$refs.membershipStatusI) this.$refs.membershipStatusI.reload();
      if (this.$refs.membershipStatusIp) this.$refs.membershipStatusIp.reload();
      if (this.$refs.membershipStatusK) this.$refs.membershipStatusK.reload();
      if (this.$refs.membershipStatusMi) this.$refs.membershipStatusMi.reload();
      if (this.$refs.membershipStatusMk) this.$refs.membershipStatusMk.reload();
      this.$store.dispatch("membership_status_refreshed");
    },
    statusColorFunc(status) {
      var col = "white";
      switch (status) {
        case "NEMA PONUDE":
          col = "grey";
          break;
        case "PL. I NEMA RAČUNA":
          col = "green";
          break;
        case "NEMA UPLATNICE":
          col = "grey";
          break;
        case "NE PLAĆA":
          col = "info";
          break;
        case "NEMA UPLATE":
          col = "error";
          break;
        case "PLAĆENO":
          col = "primary";
          break;
        case "PREPLATA":
          col = "accent";
          break;
        case "DJELOMIČNO":
          col = "yellow";
          break;
      }
      return col;
    }
  }
};
</script>
