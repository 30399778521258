var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:{
    'card--flex-toolbar-xs': _vm.$vuetify.breakpoint.xsOnly,
    'card--flex-toolbar': _vm.$vuetify.breakpoint.smAndUp
  }},[_c('v-toolbar',{staticClass:"elevation-3",attrs:{"flat":"","dense":"","color":"secondary"}},[_c('v-toolbar-title',{staticClass:"body-2 white--text"},[_vm._v(_vm._s(this.$route.meta.subtitle))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"small":"","fab":"","dark":"","color":"error","to":"/membershiplist"}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("card_membership")])],1)]}}])},[_c('span',[_vm._v("Vrste članarina")])]),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","fab":"","dark":"","color":"primary"},on:{"click":_vm.reload}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("autorenew")])],1)],1),_c('v-card-text',{class:{ 'pa-0': _vm.$vuetify.breakpoint.xsOnly }},[_c('v-container',{staticClass:"pt-0",class:{ 'pa-0': _vm.$vuetify.breakpoint.xsOnly },attrs:{"fluid":"","fill-height":""}},[_c('v-row',{attrs:{"no-gutters":"","align-space-around":"","justify-center":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{attrs:{"show-arrows":""},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab',[_vm._v("IND.ČL. PRIVATNO")]),_c('v-tab',[_vm._v("IND.ČL. PODUZEĆE")]),_c('v-tab',[_vm._v("KOLEKTIVNE ČL.")]),_c('v-tab',[_vm._v("MEĐUNARODNE IND.ČL.")]),_c('v-tab',[_vm._v("MEĐUNARODNE KOL.ČL.")])],1),_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab-item',{attrs:{"transition":"scroll-y-transition","reverse-transition":"scroll-y-transition"}},[_c('InteractiveTable',{ref:"membershipStatusI",staticClass:"xl-table",attrs:{"id":"membershipStatusI","headers":[
                  { text: 'ID', value: 'id' },
                  { text: 'Članski broj', value: 'regnum' },
                  { text: 'Ime i prezime', value: 'name', width: 230 },
                  { text: 'Poduzeće', value: 'company', width: 250 },
                  { text: 'Status', value: 'status', align: 'end' },
                  { text: 'Iznos', value: 'amount', align: 'end' },
                  { text: 'Uplatnica', value: 'invoice_num' },
                  { text: 'Datum uplate', value: 'payment_date' }
                ],"apiLoad":"membership/membershipstatuses.php?type=i","actions":[],"defaultFilter":"name","yearFilter":"","statusColorFunc":this.statusColorFunc,"defaultSort":"payment_date","defaultSortDesc":""}})],1),_c('v-tab-item',{attrs:{"transition":"scroll-y-transition","reverse-transition":"scroll-y-transition"}},[_c('InteractiveTable',{ref:"membershipStatusIp",staticClass:"xl-table",attrs:{"id":"membershipStatusIp","headers":[
                  { text: 'ID', value: 'id' },
                  { text: 'Članski broj', value: 'regnum' },
                  { text: 'Ime i prezime', value: 'name', width: 230 },
                  { text: 'Poduzeće', value: 'company', width: 250 },
                  { text: 'Status', value: 'status', align: 'end' },
                  { text: 'Iznos', value: 'amount', align: 'end' },
                  { text: 'Ponuda', value: 'quote_num' },
                  { text: 'Račun', value: 'invoice_num' },
                  { text: 'Datum uplate', value: 'payment_date' }
                ],"apiLoad":"membership/membershipstatuses.php?type=ip","actions":[],"defaultFilter":"name","yearFilter":"","statusColorFunc":this.statusColorFunc,"defaultSort":"payment_date","defaultSortDesc":""}})],1),_c('v-tab-item',{attrs:{"transition":"scroll-y-transition","reverse-transition":"scroll-y-transition"}},[_c('InteractiveTable',{ref:"membershipStatusK",staticClass:"xl-table",attrs:{"id":"membershipStatusK","headers":[
                  { text: 'ID', value: 'id' },
                  { text: 'Članski broj', value: 'regnum' },
                  { text: 'Naziv', value: 'name' },
                  { text: 'Status', value: 'status', align: 'end' },
                  { text: 'Iznos', value: 'amount', align: 'end' },
                  { text: 'Ponuda', value: 'quote_num' },
                  { text: 'Račun', value: 'invoice_num' },
                  { text: 'Datum uplate', value: 'payment_date' }
                ],"apiLoad":"membership/membershipstatuses.php?type=k","actions":[],"defaultFilter":"name","yearFilter":"","statusColorFunc":this.statusColorFunc,"defaultSort":"payment_date","defaultSortDesc":""}})],1),_c('v-tab-item',{attrs:{"transition":"scroll-y-transition","reverse-transition":"scroll-y-transition"}},[_c('InteractiveTable',{ref:"membershipStatusMi",staticClass:"xl-table",attrs:{"id":"membershipStatusMi","headers":[
                  { text: 'ID', value: 'id' },
                  { text: 'Ime i prezime', value: 'name', width: 200 },
                  { text: 'Poduzeće', value: 'company', width: 230 },
                  { text: 'Status', value: 'status', align: 'end' },
                  { text: 'Iznos', value: 'amount', align: 'end' },
                  { text: 'Ponuda', value: 'quote_num' },
                  { text: 'Račun', value: 'invoice_num' },
                  { text: 'Datum uplate', value: 'payment_date' }
                ],"apiLoad":"membership/membershipstatuses.php?type=mi","actions":[],"defaultFilter":"name","yearFilter":"","statusColorFunc":this.statusColorFunc,"defaultSort":"payment_date","defaultSortDesc":""}})],1),_c('v-tab-item',{attrs:{"transition":"scroll-y-transition","reverse-transition":"scroll-y-transition"}},[_c('InteractiveTable',{ref:"membershipStatusMk",staticClass:"xl-table",attrs:{"id":"membershipStatusMk","headers":[
                  { text: 'ID', value: 'id' },
                  { text: 'Naziv', value: 'name' },
                  { text: 'Status', value: 'status', align: 'end' },
                  { text: 'Iznos', value: 'amount', align: 'end' },
                  { text: 'Ponuda', value: 'quote_num' },
                  { text: 'Račun', value: 'invoice_num' },
                  { text: 'Datum uplate', value: 'payment_date' }
                ],"apiLoad":"membership/membershipstatuses.php?type=mk","actions":[],"defaultFilter":"name","yearFilter":"","statusColorFunc":this.statusColorFunc,"defaultSort":"payment_date","defaultSortDesc":""}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }